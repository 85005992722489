import React from 'react'
import styled from 'styled-components'
import Wave from './Wave'

const SectionGroup = styled.div`
    margin: 100px 0 0;
    background: url(${props => props.image});
    background-size: cover;
    height: 600px;
    display: grid;
    position: relative;
 
    @media (max-width: 640px) {
        height: 600px;
        margin: 0;

    }
`

const SectionTitle = styled.h1`
    color: white;
    font-size: 60px;
    margin: auto;
    line-height: 1.2;
    max-width: 650px;
    text-align: center;
    justify-self: center;
    padding: 150px;

    background: linear-gradient(140deg, #FFC531 0%, #FE2067 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
 
    @media (max-width: 640px) {
        font-size: 40px;
        padding: 50px;
    }
`
const WaveTop = styled.div`
    position: absolute;
    width: 100%;
    top: -6px;
    transform: rotate(180deg)
`
const WaveBottom = styled.div`
    position: absolute;
    width: 100%;
    bottom: -6px;
`

const Section = props => (
    <SectionGroup image={props.image}>
        <WaveBottom><Wave /></WaveBottom>
        <WaveTop><Wave /></WaveTop>
        <SectionTitle>{props.title}</SectionTitle>
    </SectionGroup>
)

export default Section