import React from 'react'
import styled from 'styled-components'

const StatsGroup = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    max-width: 750px;
    justify-items: center;
    margin: auto;
    text-align: center;
    padding: 50px 0 0 0;

    @media (max-width: 640px) {
        grid-template-columns: repeat(1, 1fr);
        max-width: 300px;
        padding: 0;
    }

`

const StatsYears = styled.div`

`

const StatsTeam = styled.div`

`

const Title1 = styled.h1`
    font-size: 200px;
    margin: 0;
    padding: 5px;
    background: linear-gradient(123deg, #A1092D 0%, #FC4E86 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 640px) {
        font-size: 150px;
    }
`

const Title2 = styled.h1`
    font-size: 200px;
    margin: 0;
    padding: 5px;
    background: linear-gradient(140deg, #FF437F 0%, #FF684A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 640px) {
        font-size: 150px;
    }
`

const Description1 = styled.p`
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    margin-top: -30px;
    background: linear-gradient(123deg, #A1092D 0%, #FC4E86 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 640px) {
        margin-top: -10px;
    }
`

const Description2 = styled.p`
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    margin-top: -30px;
    background: linear-gradient(140deg, #FF437F 0%, #FF684A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 640px) {
        margin-top: -10px;
    }

    
`

const Stats = props => (
    <StatsGroup>
        <StatsYears>
            <Title1>{props.title}</Title1>
            <Description1>{props.description}</Description1>
        </StatsYears>
        <StatsTeam>
            <Title2>{props.title2}</Title2>
            <Description2>{props.description2}</Description2>
        </StatsTeam>
    </StatsGroup>
)

export default Stats