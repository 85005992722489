import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Typical from "react-typical"
import Card from "../components/Card"
import staticdata from "../../staticdata.json"
import Cell from "../components/Cell"
import Scrollchor from 'react-scrollchor';
import Section from "../components/Section"
import Stats from "../components/Stats"

const IndexPage = () => (
  <Layout>
    <SEO title="31" />
    <div id="Domov" className="Hero">
      <div className="HeroGroup">

        <h1>Moderné aplikácie.<br />
          <span>
            <Typical
              loop={Infinity}
              wrapper="span"
              steps={[
                'Za rekordný čas.',
                3000,
                'Nadčasový dizajn.',
                3000,
                'Agilným spôsobom.',
                3000,
              ]}
            />
          </span>
        </h1>

        <p>Pridajte sa k našim klientom, ktorí využívajú výhody platformy agilného vývoja. Od návrhu, prototypu a funkčného riešenia vás delí už len pár dní.</p>

        <div className="Logos">
          <img src={require('../images/logo-sketch.png')} width="50" />
          <img src={require('../images/logo-figma.png')} width="50" />
          <img src={require('../images/logo-studio.png')} width="50" />
          <img src={require('../images/logo-framer.png')} width="50" />
          <img src={require('../images/logo-react.png')} width="50" />
          <img src={require('../images/logo-swift.png')} width="50" />
        </div>

        <div className="CallToAction">
          <Scrollchor to="#Projekty" animate={{ offset: 50 }}><Link to="#"><button>Naše projekty</button></Link></Scrollchor>
          <div><p className="Caption">Neváhajte nás kontaktovať</p></div>
          <div><p className="Description">Predvedieme vám ukážku našej platformy<br />
            a vyhotovíme stratégiu vývoja na mieru</p></div>
        </div>


      </div>
      <svg width="100%" height="172" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="white">
          <animate repeatCount="indefinite"
            fill="freeze" attributeName="d" dur="10s"
            values="M0 25.9086C277 84.5821 433 65.736 720 25.9086C934.818 -3.9019 1214.06 -5.23669 1442 8.06597C2079 45.2421 2208 63.5007 2560 25.9088V171.91L0 171.91V25.9086Z;

              M0 87.1596C316 87.1597 444 160 884 52.0001C1324 -55.9999 1320.29 34.966 1538 71.251C1814 117.251 2156 189.252 2560 87.1597V233.161L0 233.161V87.1596Z;

              M0 53.6584C158 11.0001 213 0 363 0C513 0 855.555 115.001 1154 115.001C1440 115.001 1626 -38.0004 2560 53.6585V199.66L0 199.66V53.6584Z;

              M0 87.1596C316 87.1597 444 160 884 52.0001C1324 -55.9999 1320.29 34.966 1538 71.251C1814 117.251 2156 189.252 2560 87.1597V233.161L0 233.161V87.1596Z;

              M0 25.9086C277 84.5821 433 65.736 720 25.9086C934.818 -3.9019 1214.06 -5.23669 1442 8.06597C2079 45.2421 2208 63.5007 2560 25.9088V171.91L0 171.91V25.9086Z
              "
          />
        </path>
      </svg>

    </div>

    <div id="Projekty" className="Cards">
      <h2>2 projekty. Tisíce operácií.</h2>
      <div className="CardGroup">
        <Link to="https://eformulare.sk" target="_blank">
          <Card
            title="eForm"
            description="Vytvárajte a manažujte
          všetky vaše elektronické
          formuláre z jedného miesta.
          Online."
            text="eformulare.sk →"
            image={require('../images/wallpaper6.jpg')} /></Link>
        <Link to="https://efacility.sk" target="_blank">
          <Card
            title="eFacility"
            description="Posuňte správu majetku
          na úplne novú úroveň
          intuitívnou analýzou
          komplexných dát."
            text="efacility.sk →"
            image={require('../images/wallpaper7.jpg')} /></Link>
      </div>
    </div>

    <Stats
      title="24"
      description="Team skúsených vývojárov"
      title2="14"
      description2="Rokov vývojárskych skúseností"
    />



    <Section
      image={require('../images/wallpaper8.jpg')}
      title="Kód je naša doména. Dizajn je našou vášňou." />

    <div id="Sluzby" className="Services">
      <div className="ServicesTitle">
        <h1>Zvoľte si cieľ. My vám pripravíme riešenie.</h1>
      </div>

      <div className="ServicesCells">
        <p>Naše služby vystihuje agilný vývoj - orientujeme sa v každej modernej platforme. Špecializujeme sa na komplexné projekty reagujúce na špecifické a aktuálne problémy eGovernmentu.</p>
        <p className="Caption">Naše služby</p>
        {staticdata.cells.map(cell => (
          <Cell
            title={cell.title}
            image={cell.image} />
        ))}
      </div>
    </div>

  </Layout>
)

export default IndexPage

